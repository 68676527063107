import React from 'react';
import Header from './Header';
import Footer from './Footer';

export default ({ pageContext, children }) => (
  <>
    <Header context={pageContext} />

    {children}

    <Footer context={pageContext} />
  </>
);
