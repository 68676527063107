import React from "react";
import Field from "./Field";

const FieldModal = ({ value, onChange, onDelete }) => {
  const [field, setField] = React.useState(value);
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <button
        type="button"
        className="btn-secondary sm"
        onClick={() => setOpen(!open)}
      >
        {value.label}
      </button>

      {open && (
        <div className="field-modal">
          <div className="overlay" />
          <div className="content-wrapper">
            <div className="content">
              <Field value={field} onChange={setField} />

              <button
                type="button"
                className="btn-primary"
                onClick={() => {
                  setOpen(false);
                  onChange(field);
                }}
              >
                Save
              </button>

              <button
                type="button"
                className="btn-secondary"
                onClick={() => setOpen(false)}
              >
                Cancel
              </button>

              <button
                type="button"
                className="btn-danger"
                onClick={() => {
                  onDelete();
                }}
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default FieldModal;
