import React from "react";
import SelectOptions from "./SelectOptions";

const Field = ({ value, onChange }) => {
  const handleChange = (key, newValue) => {
    onChange({ ...value, [key]: newValue });
  };

  return (
    <div>
      <h4>Manage Options</h4>

      <div className="form-group">
        <label className="control-label">Type</label>
        <select
          className="form-control"
          name="type"
          value={value.type}
          onChange={(e) => handleChange("type", e.target.value)}
        >
          <option value="text">Text</option>
          <option value="email">Email</option>
          <option value="url">URL</option>
          <option value="select">Select</option>
        </select>
      </div>

      {value.type === "select" && (
        <SelectOptions
          value={value.selectOptions}
          onChange={(newOptions) => handleChange("selectOptions", newOptions)}
        />
      )}

      <div className="form-group">
        <label className="control-label">Name</label>
        <input
          type="text"
          className="form-control"
          name="name"
          onChange={(e) => handleChange("name", e.target.value)}
          value={value.name}
        />
      </div>

      <div className="form-group">
        <label className="control-label">Label</label>
        <input
          type="text"
          className="form-control"
          name="label"
          onChange={(e) => handleChange("label", e.target.value)}
          value={value.label}
        />
      </div>

      <div className="form-group">
        <label className="control-label">Placeholder</label>
        <input
          type="text"
          className="form-control"
          name="placeholder"
          value={value.placeholder}
          onChange={(e) => handleChange("placeholder", e.target.value)}
        />
      </div>

      <div className="form-group">
        <label className="control-label">Hint</label>
        <input
          type="text"
          className="form-control"
          name="hint"
          value={value.hint}
          onChange={(e) => handleChange("hint", e.target.value)}
        />
      </div>

      <div className="form-group">
        <label className="control-label">Required</label>
        <select
          className="form-control"
          name="required"
          value={value.required}
          onChange={(e) => handleChange("required", e.target.value === "true")}
        >
          <option value={true}>Yes</option>
          <option value={false}>No</option>
        </select>
      </div>

      <div className="form-group">
        <label className="control-label">Regex validation</label>
        <input
          type="text"
          className="form-control"
          name="regex"
          value={value.regex}
          onChange={(e) => handleChange("regex", e.target.value)}
        />
      </div>
    </div>
  );
};

export default Field;
