import React from "react";
import { generateRan } from "./helpers";

const factory = () => ({ value: "", label: "", id: generateRan() });

const SelectOptions = ({ value, onChange }) => {
  const handleAdd = () => {
    onChange([...value, factory()]);
  };

  const handleDelete = (id) => {
    const idx = value.findIndex((el) => el.id === id);

    onChange([...value.slice(0, idx), ...value.slice(idx + 1)]);
  };

  const handleUpdate = (id, optionValue, optionLabel) => {
    const newOption = { id, value: optionValue, label: optionLabel };
    const idx = value.findIndex((el) => el.id === id);

    onChange([...value.slice(0, idx), newOption, ...value.slice(idx + 1)]);
  };

  return (
    <React.Fragment>
      {value.map((option) => (
        <div className="select-options-row" key={option.id}>
          <div className="form-group">
            <label className="control-label">Value</label>
            <input
              className="form-control"
              type="text"
              value={option.value}
              onChange={(e) =>
                handleUpdate(option.id, e.target.value, option.label)
              }
            />
          </div>

          <div className="form-group">
            <label className="control-label">Label</label>
            <input
              className="form-control"
              type="text"
              value={option.label}
              onChange={(e) =>
                handleUpdate(option.id, option.value, e.target.value)
              }
            />
          </div>

          <button
            type="button"
            className="btn-danger sm"
            onClick={() => handleDelete(option.id)}
          >
            &times;
          </button>
        </div>
      ))}

      <button
        type="button"
        className="btn-primary sm"
        onClick={() => handleAdd()}
      >
        +
      </button>
    </React.Fragment>
  );
};

export default SelectOptions;
