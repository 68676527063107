import React from "react";
import { ListInput, TextInput, LinkSettings } from "@raketa-cms/raketa-cms";

const MenuItem = ({ settings, onChangeItem }) => (
  <div>
    <LinkSettings
      label="Link"
      onChange={(value) => onChangeItem("link", value)}
      value={settings.link}
    />
  </div>
);

class MenuEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      items:
        props.value.length > 0
          ? props.value
          : [{ id: 1, link: LinkSettings.defaults }],
    };
  }

  render() {
    const { name } = this.props;
    const { items } = this.state;

    return (
      <div className="form-group">
        <label className="control-label">Menu items</label>
        <ListInput
          template={{ link: LinkSettings.defaults }}
          listItem={(settings, onChangeItem) => (
            <MenuItem settings={settings} onChangeItem={onChangeItem} />
          )}
          onChangeList={(_, items) => this.setState({ items })}
          items={items}
        />

        <input type="hidden" name={name} value={JSON.stringify(items)} />
      </div>
    );
  }
}

export default MenuEditor;
