import SectionTitleWidget from "./SectionTitleWidget";
import SingleImageWidget from "./SingleImageWidget";
import TextBoxWidget from "./TextBoxWidget";
import NavigationWidget from "./NavigationWidget";
import HtmlWidget from "./HtmlWidget";
import FormWidget from "./FormWidget";

export default {
  SectionTitleWidget,
  SingleImageWidget,
  TextBoxWidget,
  NavigationWidget,
  HtmlWidget,
  FormWidget,
};
