import React from 'react';
import Link from './Link';

export default ({ context: { header } }) => (
  <div className="header">
    {header.items.map((item) => (
      <Link key={item.id} settings={item.link} />
    ))}
  </div>
);
