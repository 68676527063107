import React from 'react';
import Link from './Link';

export default ({ context: { footer } }) => (
  <div className="footer">
    {footer.items.map((item) => (
      <Link key={item.id} settings={item.link} />
    ))}

    <div>
      launched by <a href="https://raketadesign.com/" target="_blank">raketa</a>
    </div>
  </div>
);
