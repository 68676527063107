import React from "react";
import PropTypes from "prop-types";
import Layout from "./frontend/Layout";
import LIBRARY from "./widgets";

const PageRender = ({ page, page_context }) => (
  <Layout pageContext={page_context}>
    {page.widgets.map((widget, idx) =>
      React.createElement(
        LIBRARY[widget.component],
        Object.assign({}, { key: idx }, widget.settings)
      )
    )}
  </Layout>
);

PageRender.propTypes = {
  page: PropTypes.object.isRequired,
};

export default PageRender;
