import React, { useState } from "react";
import FieldModal from "./FieldModal";
import { generateRan } from "./helpers";

const fieldFactory = () => ({
  type: "text",
  label: "New field",
  name: "",
  placeholder: "",
  hint: "",
  required: false,
  regex: "",
  selectOptions: [],
  id: generateRan(),
});

const rowFactory = () => ({
  fields: [],
  id: generateRan(),
});

const Options = ({ value }) => {
  const [options, setOptions] = useState(value);

  const addRow = () => {
    setOptions([...options, rowFactory()]);
  };

  const deleteRow = (rowId) => {
    const rowIdx = options.findIndex((row) => row.id === rowId);
    setOptions([...options.slice(0, rowIdx), ...options.slice(rowIdx + 1)]);
  };

  const addField = (rowId) => {
    const rowIdx = options.findIndex((row) => row.id === rowId);
    const newRow = {
      id: rowId,
      fields: [...options[rowIdx].fields, fieldFactory()],
    };

    setOptions([
      ...options.slice(0, rowIdx),
      newRow,
      ...options.slice(rowIdx + 1),
    ]);
  };

  const updateField = (rowId, fieldId, newField) => {
    const rowIdx = options.findIndex((row) => row.id === rowId);
    const row = options[rowIdx];
    const fieldIdx = row.fields.findIndex((field) => field.id === fieldId);

    const newRow = {
      id: rowId,
      fields: [
        ...row.fields.slice(0, fieldIdx),
        newField,
        ...row.fields.slice(fieldIdx + 1),
      ],
    };

    setOptions([
      ...options.slice(0, rowIdx),
      newRow,
      ...options.slice(rowIdx + 1),
    ]);
  };

  const deleteField = (rowId, fieldId) => {
    const rowIdx = options.findIndex((row) => row.id === rowId);
    const row = options[rowIdx];
    const fieldIdx = row.fields.findIndex((field) => field.id === fieldId);

    const newRow = {
      id: rowId,
      fields: [
        ...row.fields.slice(0, fieldIdx),
        ...row.fields.slice(fieldIdx + 1),
      ],
    };

    setOptions([
      ...options.slice(0, rowIdx),
      newRow,
      ...options.slice(rowIdx + 1),
    ]);
  };

  return (
    <div className="spacing-bottom">
      <h3>Options</h3>

      {options.map((row) => (
        <div className="spacing-bottom" key={row.id}>
          {row.fields.map((field) => (
            <FieldModal
              key={field.id}
              value={field}
              onChange={(newValue) => updateField(row.id, field.id, newValue)}
              onDelete={() => deleteField(row.id, field.id)}
            />
          ))}
          <button
            type="button"
            className="btn-primary sm"
            onClick={() => addField(row.id)}
          >
            +
          </button>
          <button
            type="button"
            className="btn-danger sm"
            onClick={() => deleteRow(row.id)}
          >
            Delete row
          </button>
        </div>
      ))}

      <button type="button" className="btn-primary sm" onClick={addRow}>
        +
      </button>

      <input
        type="hidden"
        name="product[options]"
        value={JSON.stringify(options)}
      />
    </div>
  );
};

export default Options;
