import React from 'react';

const Link = ({ settings, children }) => {
  const {
    label,
    link,
    id,
    className,
    target,
    rel,
  } = settings;

  return (
    <a
      href={link}
      className={className}
      id={id ? id : ''}
      target={target}
      rel={rel}
      title={label}
    >{children || label}</a>);

};

export default Link;
